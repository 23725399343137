import * as React from "react";
import { Flex, useColorMode, Text, Button } from "@chakra-ui/react";

import { useSearch } from "@svix/common/hooks/search";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@svix/common/widgets/Modal";

import EngineerMode from "src/components/EngineerMode";
import { useAppSelector } from "src/hooks/store";
import { isEE, useSvixAuth } from "src/utils";
import AppBar from "./AppBar";
import AuthAlertModal from "./AuthAlertModal";
import Drawer, { drawerWidth } from "./Drawer";
import EEDrawer from "./ee/Drawer";
import GlobalErrors from "./GlobalErrors";
import OnboardingModal from "./OnboardingDialog";

interface IAuthedChromeProps {
  children: React.ReactNode;
}

export default function AuthedChrome({ children }: IAuthedChromeProps) {
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const signupStep = useSearch("signup-step");
  const { colorMode } = useColorMode();
  const { isLoggedIn, environments } = useAppSelector((state) => state.auth);
  const hasAccess = environments.length > 0;

  return (
    <>
      <AppBar
        mobileDrawerOpen={mobileDrawerOpen}
        setMobileDrawerOpen={setMobileDrawerOpen}
      />
      {isEE ? (
        <EEDrawer
          mobileDrawerOpen={mobileDrawerOpen}
          setMobileDrawerOpen={setMobileDrawerOpen}
        />
      ) : (
        <Drawer
          mobileDrawerOpen={mobileDrawerOpen}
          setMobileDrawerOpen={setMobileDrawerOpen}
        />
      )}

      <EngineerMode />
      <Flex
        as="main"
        flexDir="column"
        flexGrow={1}
        bg="background.primary"
        color="text.primary"
        className={`theme-${colorMode}`}
        ml={[0, 0, drawerWidth]}
        p={8}
      >
        {isLoggedIn ? (
          children
        ) : (
          <LoadingIndicator style={{ display: "block", margin: "40px auto" }} />
        )}
        <GlobalErrors />
      </Flex>
      {isLoggedIn && !hasAccess && <NoAccessModal />}
      {!isEE && <OnboardingModal />}
      {!isEE && isLoggedIn && !signupStep && <AuthAlertModal />}
    </>
  );
}

function NoAccessModal() {
  const { user, logout } = useSvixAuth();

  return (
    <Modal isOpen onClose={logout} size="xl" closeOnOverlayClick={false} isCentered>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent borderRadius="lg">
        <ModalHeader>
          <Text fontSize="3xl">No Access</Text>
        </ModalHeader>
        <ModalBody>
          <Text fontSize="lg" pt={2}>
            You have no access to any active environments. Contact your organization
            administrator to get access.
          </Text>
          <Text variant="caption" pt={1} pb={2}>
            Logged in as {user?.email}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button mr={2} onClick={() => logout()}>
            Log out
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
