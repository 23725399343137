import {
  Box,
  Collapse,
  Flex,
  ButtonGroup,
  Button,
  useToast,
  Tag,
  HStack,
  Tooltip,
  Divider,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  Popover,
  PopoverArrow,
  Text,
  Link,
} from "@chakra-ui/react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoIcon from "@material-ui/icons/Info";
import { SubmitHandler, useForm, UseFormReturn } from "react-hook-form";
import { useQueryClient } from "react-query";

import * as C from "@svix/common/constants";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import Select from "@svix/common/widgets/form/Select";
import TextField from "@svix/common/widgets/form/TextField";
import Toggle from "@svix/common/widgets/form/Toggle";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import {
  SourceOut,
  SourcesApi,
  SourceType,
  OptionalSecretSources,
  RequiredSecretSources,
  RequiredSecretSource,
  OptionalSecretSource,
  SourceTypes,
  IngestSourceIn,
  SourceAuthOptionalConfig,
  SourceAuthRequiredConfig,
  SourceNames,
} from "src/api/in";
import { useAppSelector } from "src/hooks/store";

export default function SourceConfiguration({ source }: { source: SourceOut }) {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;

  const queryClient = useQueryClient();
  const defaultValues = {
    type: source.type,
    enableAuth: source.type === "genericWebhook" ? false : !!source.config.secret,
    secret: source.type === "genericWebhook" ? "" : source.config.secret,
  };
  const toast = useToast();
  const formCtx = useForm({
    defaultValues,
  });
  const { watch } = formCtx;

  const isRequiredSecretSource = RequiredSecretSources.includes(
    watch("type") as RequiredSecretSource
  );

  async function onSave(form: SourceConfigurationForm) {
    const sv = await getSvix();
    const api = new SourcesApi(sv);

    const updatedSource = {
      ...source,
      type: form.type,
      config: sourceAuthConfigFromForm(form),
    } as IngestSourceIn;

    try {
      await api.update(source.id, updatedSource);
      toast({
        title: "Configuration updated",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Error updating source",
        status: "error",
      });
    }
    formCtx.reset({}, { keepValues: true });
    queryClient.invalidateQueries([
      "environments",
      activeEnvId,
      "ingest",
      "sources",
      source.id,
    ]);
  }

  return (
    <Card
      title={
        <ConfigurationCardTitle hasAuth={watch("enableAuth") || isRequiredSecretSource} />
      }
    >
      <SourceConfigurationForm
        formCtx={formCtx}
        onSubmit={onSave}
        showActions={formCtx.formState.isDirty}
        onCancel={() => formCtx.reset(defaultValues)}
      />
    </Card>
  );
}

export interface SourceConfigurationForm {
  type: SourceType;
  enableAuth: boolean;
  secret: string | undefined;
}

export function sourceAuthConfigFromForm(
  form: SourceConfigurationForm
): SourceAuthRequiredConfig | SourceAuthOptionalConfig | undefined {
  let authConfig: { secret: string | undefined } | undefined;

  if (RequiredSecretSources.includes(form.type as RequiredSecretSource)) {
    authConfig = { secret: form.secret };
  } else if (OptionalSecretSources.includes(form.type as OptionalSecretSource)) {
    authConfig = { secret: form.enableAuth ? form.secret : undefined };
  }

  return authConfig;
}

export function SourceConfigurationForm({
  formCtx,
  onSubmit,
  onCancel,
  showCancel = true,
  showActions = true,
  cancelLabel = "Cancel",
}: {
  formCtx: UseFormReturn<SourceConfigurationForm>;
  onSubmit: SubmitHandler<SourceConfigurationForm>;
  onCancel?: () => void;
  showCancel?: boolean;
  showActions?: boolean;
  cancelLabel?: string;
}) {
  const { watch } = formCtx;
  const isOptionalSecretSource = OptionalSecretSources.includes(
    watch("type") as OptionalSecretSource
  );
  const isSecretRequired =
    RequiredSecretSources.includes(watch("type") as RequiredSecretSource) ||
    (isOptionalSecretSource && watch("enableAuth"));

  const typeLabel = (
    <HStack alignItems="center" spacing={1}>
      <Box>Source type</Box>
      <Popover trigger="hover" placement="right">
        <PopoverTrigger>
          <Flex color="text.muted" alignItems="center">
            <InfoIcon
              style={{
                width: "1rem",
                height: "1rem",
                lineHeight: 0,
              }}
            />
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody fontSize="sm" fontWeight="normal" p={3}>
            <Text>
              Choose which service the source is for. <br />
              This determines the authentication method that will be used to validate
              incoming requests.
            </Text>
            <Text mt={2}>
              Not seeing the source you want?{" "}
              <Link target="_blank" color="blue.500" href={C.contactUrl}>
                Let us know.
              </Link>
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );

  const onCancelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    formCtx.reset({}, { keepDefaultValues: true });
    onCancel?.();
  };

  return (
    <Form onSubmit={onSubmit} {...formCtx} shouldPromptOnDirty={false}>
      <Select label={typeLabel} control={formCtx.control} name="type">
        {SourceTypes.map((source) => (
          <option key={source} value={source}>
            {SourceNames[source]}
          </option>
        ))}
      </Select>
      <GeneralFormErrors />
      <Collapse in={isOptionalSecretSource}>
        <Box>
          <Divider my={4} />
          <Toggle
            label={
              <>
                <span>Enable authentication</span>
                <Tag colorScheme="green" ml={2}>
                  Recommended
                </Tag>
              </>
            }
            helpText="To validate the authenticity of the incoming events."
            control={formCtx.control}
            name="enableAuth"
          />
        </Box>
      </Collapse>
      <Collapse in={isSecretRequired}>
        <Box mt={4}>
          <TextField
            label="Secret"
            control={formCtx.control}
            name="secret"
            type="password"
            required={isSecretRequired}
            helperText="The signing secret to validate incoming events."
          />
        </Box>
      </Collapse>
      {showActions && (
        <Box mt={4}>
          <Flex justifyContent="flex-end" mt={4}>
            <ButtonGroup>
              {showCancel && (
                <Button variant="outline" onClick={onCancelClick}>
                  {cancelLabel}
                </Button>
              )}
              <SubmitButton isLoading={formCtx.formState.isSubmitting}>Save</SubmitButton>
            </ButtonGroup>
          </Flex>
        </Box>
      )}
    </Form>
  );
}

function ConfigurationCardTitle({ hasAuth }: { hasAuth: boolean }) {
  return (
    <HStack alignItems="center">
      <span>Configuration</span>
      {!hasAuth && (
        <Tooltip label="Source authentication is not enabled">
          <ErrorOutlineIcon
            style={{
              marginInlineStart: "4px",
              height: "1.2rem",
              width: "1.2rem",
              color: "var(--chakra-colors-yellow-500)",
            }}
          />
        </Tooltip>
      )}
    </HStack>
  );
}
