import { Thead, Tr, Th, Tbody, SkeletonText } from "@chakra-ui/react";
import type { ApplicationOut } from "svix";

import usePagination from "@svix/common/hooks/pagination";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import ResourceError from "@svix/common/widgets/ResourceError";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { getSvix } from "src/api";
import { useAppSelector } from "src/hooks/store";
import ApplicationRow from "./ApplicationRow";
import ApplicationsEmptyState from "./EmptyState";
import ApplicationsPageToolbar from "./Toolbar";

export default function Applications() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId) || "";
  const [applications, applicationsCtx] = usePagination(
    ["environments", activeEnvId, "applications"],
    async (iterator) => {
      const api = await getSvix();
      return api.application.list({ iterator });
    }
  );

  if (applicationsCtx.error) {
    return (
      <ResourceError resourceName="applications" onClick={applicationsCtx.refetch} />
    );
  }

  const hasNoApplications = applications?.data.length === 0;
  if (hasNoApplications) {
    return <ApplicationsEmptyState />;
  }

  return (
    <>
      <MetaTitle path={["Applications"]} />
      <ApplicationsPageToolbar />
      <Table response={applications} requestElems={applicationsCtx} variant="hover">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Uid</Th>
            <Th>ID</Th>
            <Th isNumeric>Created</Th>
          </Tr>
        </Thead>
        <Tbody>
          {applicationsCtx.isLoading && (
            <Tr>
              <TableCell align="left">
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell align="left">
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell align="left">
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell align="right">
                <SkeletonText noOfLines={1} />
              </TableCell>
            </Tr>
          )}
          {applications?.data.map((application: ApplicationOut) => (
            <ApplicationRow key={application.id} application={application} />
          ))}
        </Tbody>
      </Table>
    </>
  );
}
