import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@svix/common/widgets/Modal";

import { getApiConfiguration } from "src/api";
import {
  InvitationApi,
  InviteOut,
  OrganizationInviteStatus,
} from "src/generated/dashboard-openapi";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { switchOrganization } from "src/store/auth";
import { pushError } from "src/store/errors";
import { useLoadingManual } from "src/utils";

interface IJoinOrganizationModalProps {
  invite: InviteOut;
  token: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function JoinOrganizationModal(props: IJoinOrganizationModalProps) {
  const { invite, isOpen, onClose, token } = props;
  const isEmailVerified = useAppSelector((app) => app.auth.emailVerified);
  const history = useHistory();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  async function respondToInvite(status: OrganizationInviteStatus) {
    const config = await getApiConfiguration();
    const api = new InvitationApi(config);
    try {
      await api.respondToInviteInviteRespondOrgGroupInviteIdPost(
        invite.orgGroupInviteId,
        {
          status,
          token,
        }
      );
      if (status === "Accepted") {
        history.replace({ search: "" });
        // This will clear the active env, so we reload the page
        // to get the environments from the new org group.
        dispatch(
          switchOrganization({
            newOrgGroupId: invite.orgGroupId,
            newRole: invite.role,
          })
        );
        location.reload();
      }
    } catch (err) {
      dispatch(pushError(err));
    }
  }

  const [isAcceptSubmitting, , acceptInvite] = useLoadingManual(async () => {
    respondToInvite("Accepted");
  }, []);
  const [isDeclineSubmitting, , declineInvite] = useLoadingManual(async () => {
    await respondToInvite("Denied");
    await queryClient.invalidateQueries(["incomingInvites", token]);
  }, []);

  return (
    <Modal size="lg" onClose={onClose} isOpen={isOpen}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent borderRadius="lg">
        <ModalHeader
          bgGradient="linear(to-r, purple.300, purple.400, blue.400)"
          pt="3em"
          pb="1em"
          borderTopRadius="lg"
          color="white"
          fontSize="3xl"
        >
          You've been invited!
        </ModalHeader>
        <ModalBody mt={4}>
          <ModalCloseButton />
          <Text fontSize="md">
            <strong>{invite.inviterEmail}</strong> has invited you to join their
            organization on Svix.
          </Text>
          <Text my={3} fontSize="md">
            By joining their organization, you will abandon your applications and any
            associated messages.
          </Text>
          {!isEmailVerified && (
            <Alert status="warning" borderRadius="md" mt={2}>
              <AlertIcon />
              <div>
                <AlertTitle mr={2}>You'll need to verify your email to join</AlertTitle>
                <AlertDescription>
                  Check your email for a confirmation link.
                </AlertDescription>
              </div>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isDeclineSubmitting}
            colorScheme="red"
            variant="outline"
            onClick={declineInvite}
            isDisabled={!isEmailVerified}
            mr={3}
          >
            Decline
          </Button>
          <Tooltip
            shouldWrapChildren
            label={
              !isEmailVerified &&
              "You must verify your email before you can accept any organization invites"
            }
            placement="top"
          >
            <Button
              isLoading={isAcceptSubmitting}
              colorScheme="blue"
              isDisabled={!isEmailVerified}
              onClick={acceptInvite}
            >
              Join
            </Button>
          </Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
