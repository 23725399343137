import { useState, useEffect } from "react";
import { Text, Input, Alert, AlertIcon, ModalCloseButton } from "@chakra-ui/react";

import Button from "@svix/common/widgets/Button";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@svix/common/widgets/Modal";

import { getApiConfiguration } from "src/api";
import { AuthenticationApi } from "src/generated/dashboard-openapi";
import { Key } from "./";

interface EditKeyModalProps {
  apiKey: Key | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

export default function EditKeyModal(props: EditKeyModalProps) {
  const [keyName, setKeyName] = useState("");
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (props.apiKey) {
      setKeyName(props.apiKey.name ?? "");
    }
  }, [props.apiKey]);

  const updateKey = async () => {
    if (!props.apiKey) {
      return;
    }

    const config = await getApiConfiguration();
    const authApi = new AuthenticationApi(config);
    try {
      await authApi.updateApiTokenAuthenticationApiTokenKeyIdPut(props.apiKey.id, {
        name: keyName,
      });
    } catch (e) {
      setError(e.body?.detail || "An error occurred when updating the API key name.");
      return;
    }
    props.onSave();
    props.onClose();
  };

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen}>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>Edit API key</ModalHeader>
        <ModalBody>
          <ModalCloseButton />
          <Text fontSize="md">Key name:</Text>
          <Input value={keyName} onChange={(e) => setKeyName(e.target.value)} autoFocus />
          {error && (
            <Alert status="error" mt={4}>
              <AlertIcon />
              {error}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={updateKey} disabled={keyName.length === 0}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
