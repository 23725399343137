import { useState } from "react";
import {
  HStack,
  Box,
  Text,
  IconButton,
  Tooltip,
  useToast,
  Spacer,
  Alert,
  AlertDescription,
} from "@chakra-ui/react";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";
import { useQueryClient } from "react-query";

import useCopyToClipboard from "@svix/common/hooks/copyToClipboard";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import Mono from "@svix/common/widgets/Mono";

import { getSvix } from "src/api";
import { SourceOut, SourcesApi } from "src/api/in";
import { useAppSelector } from "src/hooks/store";

export default function SourceUrl({
  source,
  showRotateButton = false,
}: {
  source: SourceOut;
  showRotateButton: boolean;
}) {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const [_, copyToClipboard] = useCopyToClipboard(source.ingestUrl);
  const [confirmRotateTokenVisible, setConfirmRotateTokenVisible] = useState(false);
  const queryClient = useQueryClient();
  const toast = useToast();

  const rotateToken = async () => {
    const sv = await getSvix();
    const api = new SourcesApi(sv);
    try {
      await api.rotateToken(source.id);
      toast({
        title: "Token rotated",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Could not rotate token",
        description: error.detail || "Error rotating token",
        status: "error",
      });
    }
    setConfirmRotateTokenVisible(false);
    queryClient.invalidateQueries([
      "environments",
      activeEnvId,
      "ingest",
      "sources",
      source.id,
    ]);
  };

  return (
    <>
      <Card w="100%">
        <HStack gap={2} justifyContent="space-between">
          <Box>
            <Text fontSize="sm" fontWeight="semibold" mb={1}>
              Ingest URL
            </Text>
            <Mono fontSize="sm">{source.ingestUrl}</Mono>
          </Box>
          <Spacer />
          <Tooltip label="Copy to clipboard">
            <IconButton
              aria-label="Copy to clipboard"
              color="text.muted"
              icon={<FileCopyOutlinedIcon style={{ height: "16px", width: "16px" }} />}
              onClick={() => copyToClipboard()}
              size="md"
              variant="outline"
            />
          </Tooltip>
          {showRotateButton && (
            <Tooltip label="Rotate token">
              <IconButton
                aria-label="Rotate token"
                color="text.muted"
                icon={
                  <RotateLeftOutlinedIcon style={{ height: "16px", width: "16px" }} />
                }
                onClick={() => setConfirmRotateTokenVisible(true)}
                size="md"
                variant="outline"
              >
                Rotate token
              </IconButton>
            </Tooltip>
          )}
        </HStack>
      </Card>
      <ConfirmationDialog
        title="Rotate token?"
        isOpen={confirmRotateTokenVisible}
        onCancel={() => setConfirmRotateTokenVisible(false)}
        onOk={rotateToken}
        labelOk="Rotate"
        colorScheme="red"
      >
        <Text>Are you sure you want to rotate the ingest URL token?</Text>
        <Text mt={2}>
          Rotating the token will expire this URL and replace it with a new one. The old
          ingest URL will only be valid for the next 24 hours. Afterward, requests to the
          old URL will fail.
        </Text>
        <Alert status="info" borderRadius="md" variant="plain" mt={4}>
          <Box>
            <AlertDescription>
              Token rotation is limited to 2 times per 48-hour period.
            </AlertDescription>
          </Box>
        </Alert>
      </ConfirmationDialog>
    </>
  );
}
