import { useEffect } from "react";
import { Box, useToast, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import * as yup from "yup";

import { setErrors } from "@svix/common/formUtils";
import Button from "@svix/common/widgets/Button";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@svix/common/widgets/Modal";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getApiConfiguration } from "src/api";
import { queryClient } from "src/App";
import {
  OrganizationApi,
  OrganizationRole,
  OrganizationUser,
} from "src/generated/dashboard-openapi";
import RoleToggle from "./RoleToggle";

// 'Default' is a pseudo-role that we display on the 'Environment Roles'
// section of MemberScreen. When chosen, we delete the override for the user in
// the selected environment, which has the effect of 'inheriting' the
// user's org group role
export type UpdateRoleModalSelection = OrganizationRole | "Default";

interface IChangeRoleModalProps {
  member: OrganizationUser;
  isOpen: boolean;
  defaultRole?: UpdateRoleModalSelection;
  title?: string;
  onClose?: () => void;
  updateRole?: (role: UpdateRoleModalSelection) => Promise<void>;
  showRoles?: UpdateRoleModalSelection[];
  orgName?: string;
}

const schema = yup.object().shape({
  inviteEmail: yup.string().email("Must be a valid email"),
});

export default function UpdateRoleModal(props: IChangeRoleModalProps) {
  const { member, isOpen, onClose: onCloseProp, orgName } = props;
  const onClose = onCloseProp ?? (() => undefined);
  const toast = useToast();

  const formCtx = useForm({
    defaultValues: {
      role: props.defaultRole ?? member.orgGroupRole ?? ("Member" as OrganizationRole),
    },
    resolver: yupResolver(schema),
  });
  const { control, reset, formState } = formCtx;

  const onUpdateMemberRole = useMutation(async (role: UpdateRoleModalSelection) => {
    const defaultUpdate = async (role: UpdateRoleModalSelection) => {
      const api = new OrganizationApi(await getApiConfiguration());
      if (role === "Default") {
        throw new Error("'Default' cannot be selected as an org group role");
      }
      await api.changeRoleOrganizationRoleUserIdPost(member.userId, { role: role });
    };
    const doUpdate = props.updateRole ?? defaultUpdate;
    try {
      await doUpdate(role);
      queryClient.invalidateQueries(["members"]);
      onClose();
      toast({
        duration: 2000,
        title: "Role updated",
        description: member.email,
      });
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        role: props.defaultRole ?? member.orgGroupRole ?? ("Member" as OrganizationRole),
      });
    }
  }, [isOpen, reset, member, props.defaultRole]);

  const title = props.title ?? "Update Member Role";

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>{title}</ModalHeader>
        <Form onSubmit={(form) => onUpdateMemberRole.mutate(form.role)} {...formCtx}>
          <ModalBody>
            {orgName && (
              <Box mb={3}>
                <Text fontSize="md" fontWeight="medium">
                  Environment
                </Text>
                <Text fontSize="md">{orgName}</Text>
              </Box>
            )}
            <GeneralFormErrors />
            <ModalCloseButton />
            <RoleToggle control={control} showRoles={props.showRoles} name="role" />
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onClose} mr={2}>
              Cancel
            </Button>
            <SubmitButton isLoading={formState.isSubmitting}>Update Role</SubmitButton>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}
