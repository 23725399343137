import { useEffect, useState } from "react";
import {
  Collapse,
  Stack,
  useToast,
  Box,
  Skeleton,
  HStack,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { EnvironmentApi } from "svix/dist/openapi";

import { setErrors } from "@svix/common/formUtils";
import { sleep } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import CheckboxField from "@svix/common/widgets/form/Checkbox";
import TextField from "@svix/common/widgets/form/TextField";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@svix/common/widgets/Modal";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix, getApiConfiguration, getEEAdminApiConfiguration } from "src/api";
import EnvMenu from "src/components/EnvMenu";
import {
  OrganizationEnvironment,
  OrganizationOut,
  OrganizationEEApi,
  OrganizationApi,
} from "src/generated/dashboard-openapi";
import { OrganizationIn } from "src/generated/dashboard-openapi/models/OrganizationIn";
import { OrganizationRegion } from "src/generated/dashboard-openapi/models/OrganizationRegion";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { refreshLoginInfo } from "src/store/auth";
import { isEE } from "src/utils";
import EnvTagToggle from "./EnvTagRadio";
import RegionRadio from "./RegionRadio";
import { trackEvent } from "../../analytics";

const defaultValues = {
  orgName: "",
  orgEnv: "Test" as OrganizationEnvironment,
  region: "eu" as OrganizationRegion,
};

interface IAddEnvironmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddEnvironment?: (env: OrganizationOut) => void;
}

interface IImportForm {
  includeEventTypes: boolean;
  includeSettings: boolean;
  includeConnectors: boolean;
}

const copyFromEnvFormDefaultValues: IImportForm = {
  includeEventTypes: true,
  includeSettings: true,
  includeConnectors: true,
};

export default function AddEnvironmentModal(props: IAddEnvironmentModalProps) {
  const { isOpen, onClose, onAddEnvironment } = props;
  const [copyExisting, setCopyExisting] = useState(false);
  const envs = useAppSelector((state) => state.auth.environments);
  const [copyFromOrg, setCopyFromOrg] = useState<OrganizationOut>(envs[0]);

  const toast = useToast();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const formCtx = useForm({ defaultValues });
  const copyFromEnvFormCtx = useForm<IImportForm>({
    defaultValues: {
      includeEventTypes: true,
      includeSettings: true,
      includeConnectors: true,
    },
  });

  const { control, reset, formState } = formCtx;

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, reset]);

  useEffect(() => {
    copyFromEnvFormCtx.reset(copyFromEnvFormDefaultValues);
  }, [copyExisting, copyFromOrg.orgId, copyFromEnvFormCtx]);

  async function onSubmit(form: OrganizationIn) {
    try {
      // FIXME: unify once the /organization endpoint is fully migrated to Rust
      let env;
      if (isEE) {
        const api = new OrganizationEEApi(await getEEAdminApiConfiguration());
        env = await api.createOrgEeOrganizationPost(form);
      } else {
        const region = form.region ?? "eu";
        const api = new OrganizationApi(
          await getApiConfiguration({ region, noEnvSelected: true })
        );
        env = await api.createOrgOrganizationPost(form);
      }

      if (copyExisting) {
        // Wait for orgsettings to propagate
        await sleep(3000);

        const [includeEventTypes, includeSettings, includeConnectors] =
          copyFromEnvFormCtx.watch([
            "includeEventTypes",
            "includeSettings",
            "includeConnectors",
          ]);
        // Use the token for the newly created environment
        const sv = await getSvix({ envId: env.orgId, region: env.region });
        const api = new EnvironmentApi(sv._configuration);
        await api.v1EnvironmentImport({
          environmentIn: {
            eventTypes: includeEventTypes ? exportedOrgData?.eventTypes : undefined,
            settings: includeSettings ? exportedOrgData?.settings || {} : null,
            transformationTemplates: includeConnectors
              ? exportedOrgData?.transformationTemplates
              : undefined,
          },
        });
      }

      toast({
        title: "New environment created",
        description: `${env.orgId}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      await refreshLoginInfo(dispatch);
      queryClient.invalidateQueries("environments");
      trackEvent("Create Environment");
      onClose();
      if (onAddEnvironment) {
        onAddEnvironment(env);
      }
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  const { data: exportedOrgData, isLoading: isLoadingExport } = useQuery(
    ["environments", copyFromOrg.orgId, "export"],
    async () => {
      const sv = await getSvix({ envId: copyFromOrg.orgId, region: copyFromOrg.region });
      const api = new EnvironmentApi(sv._configuration);
      return api.v1EnvironmentExport({});
    }
  );

  return (
    <Modal size="xl" onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>New Environment</ModalHeader>
        <Form onSubmit={onSubmit} {...formCtx}>
          <ModalBody>
            <ModalCloseButton />
            <GeneralFormErrors />
            <Stack spacing={6}>
              <TextField
                control={control}
                name="orgName"
                label="Name"
                autoFocus
                required
                placeholder="e.g. Staging"
              />
              {!isEE && <RegionRadio control={control} name="region" />}
              <EnvTagToggle control={control} name="orgEnv" />
              <Card>
                <HStack justifyContent="space-between" alignItems="top" w="100%">
                  <FormControl display="flex" alignItems="center" w="auto" h="auto">
                    <Switch
                      id="copyExisting"
                      onChange={() => setCopyExisting(!copyExisting)}
                      isChecked={copyExisting}
                    />
                    <FormLabel htmlFor="copyExisting" mb={0} ml={2}>
                      Copy existing environment
                    </FormLabel>
                  </FormControl>

                  {copyExisting && (
                    <EnvMenu
                      showAll={false}
                      selectedEnvId={copyFromOrg.orgId}
                      setSelectedEnvId={(env) =>
                        setCopyFromOrg(envs.find((e) => e.orgId === env)!)
                      }
                    />
                  )}
                </HStack>
                <Collapse in={copyExisting}>
                  <Skeleton isLoaded={!!exportedOrgData} minHeight={12} mt={2}>
                    {exportedOrgData && (
                      <Box mt={1}>
                        {exportedOrgData.eventTypes.length > 0 && (
                          <CheckboxField
                            control={copyFromEnvFormCtx.control}
                            name="includeEventTypes"
                          >
                            Copy Event Types ({exportedOrgData.eventTypes.length})
                          </CheckboxField>
                        )}
                        {exportedOrgData.transformationTemplates.length > 0 && (
                          <CheckboxField
                            control={copyFromEnvFormCtx.control}
                            name="includeConnectors"
                          >
                            Copy Connectors (
                            {exportedOrgData.transformationTemplates.length})
                          </CheckboxField>
                        )}
                        <CheckboxField
                          isDisabled={!exportedOrgData.settings}
                          control={copyFromEnvFormCtx.control}
                          name="includeSettings"
                        >
                          Copy Environment settings
                        </CheckboxField>
                      </Box>
                    )}
                  </Skeleton>
                </Collapse>
              </Card>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <SubmitButton
              isLoading={formState.isSubmitting}
              isDisabled={copyExisting && isLoadingExport}
            >
              Create
            </SubmitButton>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}
